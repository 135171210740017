import './NotFound.scss';

import React from 'react';
import { RouteComponentProps, Link } from '@reach/router';

import PageContainer from './PageContainer';

interface Props extends RouteComponentProps {}

const NotFound: React.FC<Props> = (props) => {
  return (
    <PageContainer>
      <div className="NotFound">
        <div>
          <h1>Page not found!</h1>

          <p>We’re sorry, but this page does not exist.</p>

          <p>
            Please visit the{' '}
            <Link className="NotFound-link" to="/">
              MUSAIC homepage
            </Link>{' '}
            to search for videos, hangouts and more.
          </p>

          <p>
            If you feel like you’ve received this message in error, please
            contact{' '}
            <Link className="NotFound-link" to="/contact">
              Tech Support
            </Link>
            .
          </p>
        </div>
      </div>
    </PageContainer>
  );
};

export default NotFound;
