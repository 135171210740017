import { gql } from '@apollo/client';

export { CategoriesQuery } from './__generated__/CategoriesQuery';

export const CATEGORIES_QUERY = gql`
  query CategoriesQuery {
    categories {
      id
      name
    }
  }
`;
