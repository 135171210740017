import React from 'react';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import Select, { Props as SelectProps } from 'react-select/async';

import {
  WorksSearchQuery,
  WorksSearchQueryVariables
} from './__generated__/WorksSearchQuery';

const WORK_SEARCH_QUERY = gql`
  query WorksSearchQuery($searchTerm: String!) {
    works(searchTerm: $searchTerm) {
      id
      commonWorkName
    }
  }
`;

interface Props
  extends Omit<SelectProps<{ value: number; label: string }>, 'loadOptions'> {
  onChange(composerIds: number[]): void;
}

const SearchWorksSelect: React.FC<Props> = (props) => {
  const { onChange, ...selectProps } = props;

  return (
    <Query<WorksSearchQuery, WorksSearchQueryVariables>
      query={WORK_SEARCH_QUERY}
    >
      {({ refetch }) => {
        return (
          <Select
            noOptionsMessage={() => 'Start typing to see results'}
            {...selectProps}
            loadOptions={(inputValue) => {
              return refetch({ searchTerm: inputValue }).then(({ data }) => {
                if (data == null) {
                  return [];
                }

                return data.works.map((work) => ({
                  value: work.id,
                  label: work.commonWorkName
                }));
              });
            }}
            onChange={(
              options: { value: number } | Array<{ value: number }> | null
            ) => {
              if (Array.isArray(options)) {
                onChange(options.map((option) => option.value));
              } else if (options != null) {
                onChange([options.value]);
              } else {
                onChange([]);
              }
            }}
          />
        );
      }}
    </Query>
  );
};

export default SearchWorksSelect;
