import React from 'react';
import styled from 'styled-components';

import buttonCSS from './styles/buttonCss';

type HTMLButtonElementProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface Props extends HTMLButtonElementProps {
  link?: boolean;
  fullWidth?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { link, fullWidth, children, ...buttonProps } = props;

  return (
    <button type="button" {...buttonProps} ref={ref}>
      {children}
    </button>
  );
});

export default styled(Button)`
  ${buttonCSS}
`;
