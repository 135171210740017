import React from 'react';
import { Query } from '@apollo/client/react/components';
import { Redirect } from '@reach/router';

import {
  CURRENT_USER_QUERY,
  CurrentUserQuery
} from 'components/queries/CurrentUserQuery';

interface Props {
  role?: 'partner';
}

const RequireUser: React.FC<Props> = (props) => {
  const { role, children } = props;

  return (
    <Query<CurrentUserQuery> query={CURRENT_USER_QUERY}>
      {({ data, loading }) => {
        if (loading) {
          return null;
        }

        if (
          data == null ||
          data.currentUser == null ||
          (role != null &&
            !data.currentUser.roles.includes('admin') &&
            !data.currentUser.roles.includes(role))
        ) {
          return <Redirect to="/" noThrow />;
        }

        return <>{children}</>;
      }}
    </Query>
  );
};

export default RequireUser;
