declare global {
  interface Window {
    bugsnagClient?: {
      use(bugsnagReact: unknown): React.ElementType;
      notify(error: Error): void;
    };

    bugsnag__react?(react: typeof React): unknown;

    ENV: {
      awsAccessKeyId: string | null;
      awsRegion: string | null;
      s3BucketName: string | null;
      recaptchaSiteKey: string | null;
    };
  }
}

import 'styles/application.scss';

import 'core-js/es/set';
import 'core-js/es/map';
import 'core-js/es/math/sign';
import 'core-js/es/math/trunc';
import 'core-js/es/number/is-nan';
import 'core-js/es/string/repeat';

import React from 'react';
import * as ReactDOM from 'react-dom';

import { ApolloProvider } from '@apollo/client';

import apolloClient from 'apolloClient';
import Application from 'components/Application';

const rootElement = document.createElement('div');

rootElement.id = 'application';

document.body.appendChild(rootElement);

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Application />
  </ApolloProvider>,
  rootElement
);
