import './Paragraphs.scss';

import React from 'react';

interface Props {
  content: string | null;
}

const Paragraphs: React.FC<Props> = (props) => {
  const { content } = props;

  if (content == null) {
    return null;
  }

  return (
    <>
      {content.split(/\n+/).map((paragraph, index) => (
        <p className="Paragraph" key={index}>
          {paragraph}
        </p>
      ))}
    </>
  );
};

export default Paragraphs;
