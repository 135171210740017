import './Pagination.scss';

import React from 'react';
import { Link } from '@reach/router';
import { range, clamp } from 'lodash';

interface Props {
  totalPages: number;
  page: number;
}

const Pagination: React.FC<Props> = (props) => {
  const { totalPages, page } = props;

  const minPage = clamp(page - 4, 1, Infinity);
  const maxPage = clamp(page + 4, 1, totalPages);

  const pageNumbers = range(minPage, maxPage + 1);

  return (
    <div className="Pagination">
      {page > 1 && (
        <Link className="Pagination-item--link" to={buildPagePath(page - 1)}>
          &larr;
        </Link>
      )}

      {minPage > 1 && <div className="Pagination-item">…</div>}

      {pageNumbers.map((number) => (
        <Link
          key={number}
          to={buildPagePath(number)}
          className={
            page === number
              ? 'Pagination-item--link --is-currentPage'
              : 'Pagination-item--link'
          }
        >
          {number}
        </Link>
      ))}

      {maxPage < totalPages && <div className="Pagination-item">…</div>}

      {page !== totalPages && (
        <Link className="Pagination-item--link" to={buildPagePath(page + 1)}>
          &rarr;
        </Link>
      )}
    </div>
  );
};

const buildPagePath = (page: number): string => {
  const url = new URL(window.location.href);

  url.searchParams.set('page', page.toString());

  return `${url.pathname}${url.search}`;
};

export default Pagination;
