import './Input.scss';

import React from 'react';
import classNames from 'classnames';

type HTMLInputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface Props extends HTMLInputElementProps {
  fullWidth: boolean;
  inverted: boolean;
}

export const defaultProps = {
  type: 'text',
  fullWidth: false,
  inverted: false
};

class Input extends React.PureComponent<Props> {
  static defaultProps = defaultProps;

  render() {
    const { fullWidth, inverted, ...inputProps } = this.props;

    return (
      <input
        className={classNames('Input', {
          'Input--inverted': inverted
        })}
        {...inputProps}
      />
    );
  }
}

export default Input;
