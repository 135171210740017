import './EmphasizedHeading.scss';

import React from 'react';

interface Props {
  children: string;
}

const EmphasizedHeading: React.FC<Props> = (props) => {
  return (
    <div className="EmphasizedHeading">
      <div className="EmphasizedHeading-content">{props.children}</div>
    </div>
  );
};

export default EmphasizedHeading;
