import './index.scss';

import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Query } from '@apollo/client/react/components';

import * as Core from 'components/core';

import LiveEventHeader from './LiveEventHeader';
import Hero from './Hero';
import Description from './Description';
import LiveEventFooter from './LiveEventFooter';
import CategoryTile from './CategoryTile';

import {
  CATEGORIES_QUERY,
  CategoriesQuery
} from 'components/queries/CategoriesQuery';

interface Props extends RouteComponentProps {}

const Home: React.FC<Props> = () => {
  return (
    <Core.PageContainer>
      <LiveEventHeader />

      <Hero />

      <Description />

      <Query<CategoriesQuery> query={CATEGORIES_QUERY}>
        {({ data }) => {
          if (data == null || data.categories == null) {
            return null;
          }

          return (
            <div className="Home-categoriesGrid">
              <CategoryTile
                to="/search?orderField=created_at&amp;orderDirection=desc"
                categoryOverride="New Videos"
                badgeOverride="N"
                inverted
              />

              {data.categories
                .filter((category) => category.name !== 'Live Events')
                .map((category) => (
                  <CategoryTile
                    key={category.id}
                    to={`/search?categoryId=${category.id}`}
                    categoryName={category.name}
                  />
                ))}

              <CategoryTile
                to="/artists"
                categoryOverride="Artist Index"
                badgeOverride="AI"
                inverted
              />
            </div>
          );
        }}
      </Query>

      <Core.Divider />

      <LiveEventFooter />
    </Core.PageContainer>
  );
};

export default Home;
