import React from 'react';
import { Router } from '@reach/router';

import * as Core from 'components/core';
import Notifications, { NotificationsProvider } from './Notifications';
import Header from './Header';
import Footer from './Footer';
import Home from './home';

const Search = React.lazy(() => import('./search/Search'));
const Videos = React.lazy(() => import('./videos'));
const Register = React.lazy(() => import('./Register'));
const Passwords = React.lazy(() => import('./passwords'));
const SignIn = React.lazy(() => import('./SignIn'));
const Account = React.lazy(() => import('./Account'));
const Playlists = React.lazy(() => import('./playlists'));
const LiveEvents = React.lazy(() => import('./live-events'));
const Artists = React.lazy(() => import('./artists'));
const Partners = React.lazy(() => import('./partners'));
const Pages = React.lazy(() => import('./pages'));
const About = React.lazy(() => import('./AboutPage'));

const Application: React.FC = () => {
  return (
    <Core.BugsnagErrorBoundary>
      <NotificationsProvider>
        <Core.DocumentTitle>
          <Header />

          <Notifications />

          <main>
            <React.Suspense fallback={<Core.Loader />}>
              <Core.BugsnagErrorBoundary>
                <Router primary={false}>
                  <Home path="/" />
                  <Search path="/search" />
                  <Videos path="/videos/*" />
                  <Register path="/register" />
                  <Passwords path="/reset-password" />
                  <SignIn path="/sign-in" />
                  <Account path="/account" />
                  <Playlists path="/playlists/*" />
                  <LiveEvents path="/live-events/*" />
                  <Artists path="/artists" />
                  <Partners path="/partners/*" />
                  <About path="/pages/about" />
                  <Pages path="/pages/*" />
                  <Core.NotFound path="*" />
                </Router>
              </Core.BugsnagErrorBoundary>
            </React.Suspense>
          </main>

          <Footer />
        </Core.DocumentTitle>
      </NotificationsProvider>
    </Core.BugsnagErrorBoundary>
  );
};

export default Application;
