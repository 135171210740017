import React, { useState, useRef, MutableRefObject } from 'react';
import TetherComponent from 'react-tether';

import useClickOutside from 'utils/useClickOutside';

interface Props {
  attachment?: string;
  targetAttachment?: string;

  renderButton(props: {
    ref(element: HTMLButtonElement | null): void;
    handleClick(): void;
  }): React.ReactNode;

  renderItems(props: { closeMenu(): void }): React.ReactNode;
}

const Menu: React.FC<Props> = (props) => {
  const {
    attachment = 'top left',
    targetAttachment = 'bottom left',
    renderButton,
    renderItems
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLElement>();
  const itemsRef = useRef<HTMLElement>();

  useClickOutside(
    [buttonRef, itemsRef],
    () => {
      setIsOpen(false);
    },
    isOpen
  );

  return (
    <TetherComponent
      attachment={attachment}
      targetAttachment={targetAttachment}
      offset="-4px 0"
      constraints={[
        {
          to: 'scrollParent',
          attachment: 'together'
        }
      ]}
      renderTarget={(ref: MutableRefObject<HTMLElement>) =>
        renderButton({
          ref: (element) => {
            buttonRef.current = element ?? undefined;

            if (element != null) {
              ref.current = element;
            }
          },
          handleClick: () => {
            setIsOpen(!isOpen);
          }
        })
      }
      renderElement={(ref: MutableRefObject<HTMLElement>) =>
        isOpen && (
          <div
            ref={(element) => {
              itemsRef.current = element ?? undefined;

              if (element != null) {
                ref.current = element;
              }
            }}
          >
            {renderItems({
              closeMenu: () => {
                setIsOpen(false);
              }
            })}
          </div>
        )
      }
    />
  );
};

export default Menu;
