import './FormGroup.scss';

import React from 'react';
import { uniqueId } from 'lodash';

type Children =
  | React.ReactElement
  | ((options: { inputId: string }) => React.ReactNode);

export interface Props {
  label?: string;
  required?: boolean;
  hint?: React.ReactNode;
  touched?: boolean;
  error?: string;
  children: Children;
}

class FormGroup extends React.Component<Props> {
  inputId = uniqueId('input');

  render() {
    const { label, hint, touched, error, children } = this.props;

    return (
      <div>
        <label className="FormGroup-label" htmlFor={this.inputId}>
          {label}
        </label>

        {typeof children === 'function'
          ? children({ inputId: this.inputId })
          : children}

        {hint && <div>{hint}</div>}

        {touched && error != null && (
          <div className="FormGroup-error">{error}</div>
        )}
      </div>
    );
  }
}

export default FormGroup;
