import React from 'react';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import Select, { Props as SelectProps } from 'react-select/async';

import {
  InstrumentsSearchQuery,
  InstrumentsSearchQueryVariables
} from './__generated__/InstrumentsSearchQuery';

const INSTRUMENT_SEARCH_QUERY = gql`
  query InstrumentsSearchQuery($searchTerm: String!) {
    instruments(searchTerm: $searchTerm) {
      id
      name
    }
  }
`;

interface Props
  extends Omit<SelectProps<{ value: number; label: string }>, 'loadOptions'> {
  onChange(instumentIds: number[]): void;
}

const SearchInstrumentsSelect: React.FC<Props> = (props) => {
  const { onChange, ...selectProps } = props;

  return (
    <Query<InstrumentsSearchQuery, InstrumentsSearchQueryVariables>
      query={INSTRUMENT_SEARCH_QUERY}
    >
      {({ refetch }) => {
        return (
          <Select
            noOptionsMessage={() => 'Start typing to see results'}
            {...selectProps}
            loadOptions={(inputValue) => {
              return refetch({ searchTerm: inputValue }).then(({ data }) => {
                if (data == null) {
                  return [];
                }

                return data.instruments.map((instrument) => ({
                  value: instrument.id,
                  label: instrument.name
                }));
              });
            }}
            onChange={(
              options: { value: number } | Array<{ value: number }> | null
            ) => {
              if (Array.isArray(options)) {
                onChange(options.map((option) => option.value));
              } else if (options != null) {
                onChange([options.value]);
              } else {
                onChange([]);
              }
            }}
          />
        );
      }}
    </Query>
  );
};

export default SearchInstrumentsSelect;
