import './Notifications.scss';

import React, { createContext, useContext, useState, useMemo } from 'react';
import { uniqueId } from 'lodash';

interface Notification {
  id: string;
  message: string;
}

const NotificationsContext = createContext<{
  notifications: Notification[];
  showNotification(message: string): void;
  hideNotification(id: string): void;
}>({
  notifications: [],
  showNotification: () => {},
  hideNotification: () => {}
});

export const NotificationsProvider: React.FC = (props) => {
  const { children } = props;
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const value = useMemo(() => {
    const showNotification = (message: string) => {
      const id = uniqueId();

      setNotifications([
        ...notifications,
        {
          id,
          message
        }
      ]);

      setTimeout(() => {
        hideNotification(id);
      }, 3000);
    };

    const hideNotification = (id: string) => {
      setNotifications(
        notifications.filter((notification) => notification.id !== id)
      );
    };

    return {
      notifications,
      showNotification,
      hideNotification
    };
  }, [notifications, setNotifications]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);

const Notifications: React.FC = () => {
  const { notifications } = useNotifications();

  return (
    <div>
      {notifications.length > 0 && (
        <div className="Notification">
          {notifications.map((notification, index) => (
            <div className="Notification-message" key={index}>
              {notification.message}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Notifications;
