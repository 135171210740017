import React from 'react';

import ErrorBoundary from './ErrorBoundary';
import ErrorBoundaryMessage from './ErrorBoundaryMessage';

const BugsnagOrErrorBoundary: React.ElementType =
  window.bugsnagClient && window.bugsnag__react
    ? window.bugsnagClient.use(window.bugsnag__react(React))
    : ErrorBoundary;

/*
 * This renders Bugsnag's error boundary or <FallbackErrorBoundary />.
 */
const BugsnagErrorBoundary = ({ children }: { children: React.ReactNode }) => (
  <BugsnagOrErrorBoundary FallbackComponent={ErrorBoundaryMessage}>
    {children}
  </BugsnagOrErrorBoundary>
);

export default BugsnagErrorBoundary;
