import React from 'react';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import Select from 'react-select';

import { PeriodsQuery } from './__generated__/PeriodsQuery';

const PERIODS_QUERY = gql`
  query PeriodsQuery {
    periods {
      id
      name
    }
  }
`;

interface Props {
  onChange(artistIds: number[]): void;
}

const PeriodsSelect: React.FC<Props> = (props) => {
  const { onChange, ...selectProps } = props;

  return (
    <Query<PeriodsQuery> query={PERIODS_QUERY}>
      {({ data }) => {
        return (
          <Select
            {...selectProps}
            options={
              data != null && data.periods != null
                ? data.periods.map((period) => ({
                    value: period.id,
                    label: period.name
                  }))
                : []
            }
            onChange={(
              options: { value: number } | Array<{ value: number }> | null
            ) => {
              if (Array.isArray(options)) {
                onChange(options.map((option) => option.value));
              } else if (options != null) {
                onChange([options.value]);
              } else {
                onChange([]);
              }
            }}
          />
        );
      }}
    </Query>
  );
};

export default PeriodsSelect;
