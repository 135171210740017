import React from 'react';
import { Link } from '@reach/router';
import { Query } from '@apollo/client/react/components';

import Loader from './Loader';

import {
  CURRENT_USER_QUERY,
  CurrentUserQuery
} from 'components/queries/CurrentUserQuery';

const RequireUserOverlay: React.FC = (props) => {
  const { children } = props;

  return (
    <Query<CurrentUserQuery> query={CURRENT_USER_QUERY}>
      {({ data, loading }) => {
        if (loading) {
          return <Loader />;
        }

        if (data == null || data.currentUser == null) {
          return (
            <div>
              {children}

              <div>
                Please <Link to="/sign-in">Sign In</Link> or{' '}
                <Link to="/register">Register</Link> to continue viewing
                content.
              </div>
            </div>
          );
        }

        return <>{children}</>;
      }}
    </Query>
  );
};

export default RequireUserOverlay;
