import './CategoryTile.scss';

import React from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';

import * as Core from 'components/core';

interface Props {
  to: string;
  categoryOverride?: string;
  badgeOverride?: string;
  categoryName?: string;
  inverted?: boolean;
}

export const defaultProps = {
  inverted: false
};

const CategoryTile: React.FC<Props> = (props) => {
  const { categoryName, to, categoryOverride, inverted, badgeOverride } = props;

  return (
    <Link
      to={to}
      className={classNames('CategoryTile', {
        'CategoryTile--inverted': inverted
      })}
      style={{
        backgroundImage:
          categoryName != null && TILE_IMAGES[categoryName] != null
            ? `url(${TILE_IMAGES[categoryName].pattern})`
            : undefined
      }}
    >
      <div className="CategoryTile-liner">
        {categoryName && <Core.CategoryBadge category={categoryName} />}
        {badgeOverride && <Core.Badge content={badgeOverride} />}

        <div className="CategoryTile-label">
          {categoryName ? categoryName : categoryOverride}
        </div>
      </div>
    </Link>
  );
};

const TILE_IMAGES: Record<string, { pattern: string }> = {
  'Orchestral Excerpts': {
    pattern: require('assets/images/category-patterns/badges/orchestral-excerpts.svg')
  },
  'Master Classes': {
    pattern: require('assets/images/category-patterns/badges/master-classes.svg')
  },
  'Audition Preparation': {
    pattern: require('assets/images/category-patterns/badges/audition-preparation.svg')
  },
  Reflections: {
    pattern: require('assets/images/category-patterns/badges/reflections.svg')
  },
  Performances: {
    pattern: require('assets/images/category-patterns/badges/performances.svg')
  },
  'How-To': {
    pattern: require('assets/images/category-patterns/badges/how-to.svg')
  },
  'Live Events': {
    pattern: require('assets/images/category-patterns/badges/live-events.svg')
  }
};

export default CategoryTile;
