import React from 'react';

export interface Props {
  size: string;
}

export const defaultProps = {
  size: '70px'
};

class Logo extends React.PureComponent<Props> {
  static defaultProps = defaultProps;

  render() {
    return (
      <img
        width={this.props.size}
        height={this.props.size}
        src={require('assets/images/musaic-logo.png')}
        alt="Musaic"
      />
    );
  }
}

export default Logo;
